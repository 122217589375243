import { useI18n } from "@solid-primitives/i18n";
import { useNavigate } from "@solidjs/router";

export default function BackButton() {
  const [t] = useI18n();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  return (
    <button class="text-2xl m-6 text-primary-50 h-24 mx-auto" onClick={goBack}>
      <span class="align-middle px-4 py-2 text-sm hover:underline border-solid border-2 rounded-md">
        {t("btn_back")}
      </span>
    </button>
  );
}
